import React from 'react';
import { useLocation, redirectTo } from '@reach/router';
import queryString from 'query-string';

import SEO from '../components/Seo/seo';
import GridContainer from '../components/Grid/GridContainer';

const NotFoundPage = ({ data }) => {
    const location = useLocation();

    if (location.pathname.includes('email')) {
        const blogId = location.pathname.split('/')[2];

        const blogKey = data.allBlogs.edges.reduce((a, v) => {
            const { key, id } = v.node;

            if (key === blogId) {
                a = id;
            }

            return a;
        }, '');
        console.log(location);

        redirectTo(location.origin);
    // redirectTo(`blog/posts/${blogKey}`)
    }

    return (
        <div style={{ marginTop: 70, marginLeft: 15, marginRight: 15 }}>
            <SEO title="404: Not found" />
            <GridContainer justify="center" direction="column">
                <h1>Oops...</h1>
                <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
            </GridContainer>

        </div>
    );
};

export const query = graphql`
    query BlogsQuery {
              allBlogs {
                edges {
                  node {
                    key
                    id
                  }
                }
              }
            }
`;

export default NotFoundPage;
